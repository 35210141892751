import {createAction, props} from '@ngrx/store';
import { Project } from './project.model';

export enum ProjectActionsTypes {
    GET_ALL = '[Project] Get All Project',
    GET_ALL_SUCCESS = '[Project] Get All Project success',

    GET = '[Project] Get Project',
    GET_SUCCESS = '[Project] Get Project success',

    CREATE = '[Project] Create Project',
    CREATE_SUCCESS = '[Project] Create Project success',

    MAKE_RETOUR = '[Project] Make retour Project',

    UPDATE = '[Project] Update Project',
    UPDATE_SUCCESS = '[Project] Update Project success',

    UPLOAD_RESULT = '[Project] Upload result',
    UPLOAD_RESULT_SUCCESS = '[Project] Upload result success',

    START_LOADING = '[Project] Start Loading',

    LOAD_CHEMINE = '[Project] Load Chemine',
    LOAD_FOYER = '[Project] Load Foyer',
    LOAD_POELES = '[Project] Load Poeles',
    LOAD_JCBORDELET = '[Project] Load JCBORDELET',

    LOAD_CHEMINE_SUCCESS = '[Project] Load Chemine success',
    LOAD_FOYER_SUCCESS = '[Project] Load Foyer success',
    LOAD_POELES_SUCCESS = '[Project] Load Poeles success',
    LOAD_JCBORDELET_SUCCESS = '[Project] Load JCBORDELET success',
    SEND_MAIL_NOTIFICATION = "[Project] Send mail notification",
    SEND_MAIL_NOTIFICATION_SUCCESS = "[Project] Send mail notification success",

    ERROR = '[Project] Error'
}

export const getAllProject = createAction(
    ProjectActionsTypes.GET_ALL,
);

export const getAllProjectSuccess = createAction(
    ProjectActionsTypes.GET_ALL_SUCCESS,
    props<{projects: Project[]}>()
);

export const getProject = createAction(
    ProjectActionsTypes.GET,
    props<{projectId: string}>()
);

export const getProjectSuccess = createAction(
    ProjectActionsTypes.GET_SUCCESS,
    props<{project: Project}>()
);

export const createProject = createAction(
    ProjectActionsTypes.CREATE,
    props<{project, formData, user: any}>()
);

export const makeRetourProject = createAction(
    ProjectActionsTypes.MAKE_RETOUR,
    props<{project: any, formData: any}>()
);

export const createProjectSuccess = createAction(
    ProjectActionsTypes.CREATE_SUCCESS,
    props<{project: Project}>()
);

export const updateProject = createAction(
    ProjectActionsTypes.UPDATE,
    props<{projectId: string; data: any, redirect?: boolean}>()
);

export const updateProjectSuccess = createAction(
    ProjectActionsTypes.UPDATE_SUCCESS,
    props<{project: Project}>()
);

export const raiseError = createAction(
    ProjectActionsTypes.ERROR,
    props<{error}>()
);

export const startLoading = createAction(
    ProjectActionsTypes.START_LOADING
);

export const uploadResult = createAction(
    ProjectActionsTypes.UPLOAD_RESULT,
    props<{formData: any, project: any}>()
);

export const upladResultSuccess = createAction(
    ProjectActionsTypes.UPLOAD_RESULT_SUCCESS,
    props<{projectId: any}>()
);

export const loadChemine = createAction(
    ProjectActionsTypes.LOAD_CHEMINE
);

export const loadFoyer = createAction(
    ProjectActionsTypes.LOAD_FOYER
);

export const loadPoeles = createAction(
    ProjectActionsTypes.LOAD_POELES
);

export const loadJcbordelet = createAction(
    ProjectActionsTypes.LOAD_JCBORDELET
);


export const loadChemineSuccess = createAction(
    ProjectActionsTypes.LOAD_CHEMINE_SUCCESS,
    props<{data: any[]}>()
);

export const loadFoyerSuccess = createAction(
    ProjectActionsTypes.LOAD_FOYER_SUCCESS,
    props<{data: any[]}>()
);

export const loadPoelesSuccess = createAction(
    ProjectActionsTypes.LOAD_POELES_SUCCESS,
    props<{data: any[]}>()
);

export const loadJcbordeletSuccess = createAction(
    ProjectActionsTypes.LOAD_JCBORDELET_SUCCESS,
    props<{data: any[]}>()
);

export const sendMailNotification = createAction(
    ProjectActionsTypes.SEND_MAIL_NOTIFICATION,
    props<{userEmail: string, project: any}>()
);
export const sendMailNotificationSuccess = createAction(
    ProjectActionsTypes.SEND_MAIL_NOTIFICATION_SUCCESS
);
