import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RequestService {
   apiUrl = environment.apiUrl;
   serverUrl = environment.serverUrl;
  constructor(
    private http: HttpClient
  ) { }

  post(collection: string, body: any, path?: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/${collection}${path ? '/' + path : ''}`, body);
  }

  put(collection: string, body: any, path?: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/${collection}${path ? '/' + path : ''}`, body);
  }

  get(collection: string, path?: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${collection}${path ? '/' + path : ''}`);
  }

  _post(collection: string, body: any, path?: string): Observable<any> {
    return this.http.post(`${this.serverUrl}/${collection}${path ? '/' + path : ''}`, body);
  }
  _getProductInfo(body): Promise<any> {
    return this.http.post(`${this.serverUrl}/produit`, body).toPromise()
  }
}
