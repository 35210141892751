import { createReducer, on, Action } from '@ngrx/store';
import { initialUserState, UserState } from './user.state';
import * as UserActions from './user.actions';

const reducer = createReducer(
    initialUserState,
    on(
        UserActions.createUserSuccess,
        (state, {user}) => {
            return {
                ...state,
                actual: user,
                loading: false,
                error: null
            };
        }
    ),
    on(
        UserActions.updateUserSuccess,
        (state, {user}) => {
            if (state.actual && state.selected && state.actual._id === state.selected._id) {
                return {
                    ...state,
                    actual: user,
                    selected: user,
                    loading: false,
                    error: null
                };
            } else {
                return {
                    ...state,
                    selected: user,
                    loading: false,
                    error: null
                };
            }
        }
    ),
    on(
        UserActions.checkUserLoginSuccess,
        (state, {user}) => {
            return {
                ...state,
                loading: false,
                selected: user
            };
        }
    ),
    on(
        UserActions.getAllUserSuccess,
        (state, {users}) => {
            return {
                ...state,
                list: users,
                loading: false,
                error: null
            };
        }
    ),
    on(
        UserActions.logoutUser,
        (state) => {
            return {
                ...state,
                selected: null,
                list: [],
                error: null,
                loading: false
            };
        }
    ),
    on(
        UserActions.startLoading,
        (state) => {
            return {
                ...state,
                loading: true
            };
        }
    ),
    on(
        UserActions.raiseError,
        (state, {error}) => {
            return {
                ...state,
                error,
                loading: false
            };
        }
    )
);

// tslint:disable-next-line:typedef
export function userReducer(state: UserState|undefined, action: Action) {
    return reducer(state, action);
}
