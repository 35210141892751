<app-toolbar></app-toolbar>

<div class="container-fluid no-gutters nopadding tittle">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3><span class="material-icons align-middle">visibility</span> {{'COMMON.DETAILS'| translate}}</h3>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">

    <div class="w-card-body" *ngIf="project">

        <div class="row">
            <div class="col-5">
                <div class="info-item">
                    <div class="label"><mat-icon>ad_units</mat-icon> ID :</div>
                    <p>{{project._id}}</p>
                </div>
              <div class="info-item" *ngIf="user?.role === 'admin'">
                <div class="label"><mat-icon>person</mat-icon>{{'COMMON.CLIENT'| translate}} :</div>
                <p>{{project?.clientName}}</p>
              </div>
              <div class="info-item" *ngIf="user?.role === 'admin' && project.pimper">
                <div class="label"><mat-icon>person_pin</mat-icon> {{'COMMON.PIMPER'| translate}} :</div>
                <div class="select-container" >
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Selectionner
                    </mat-label>
                    <mat-select [(ngModel)]="project.pimper._id">
                      <mat-option *ngFor="let u of pimpers" [value]="u._id">{{u.firstName}} {{u.lastName}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
                <div class="info-item">
                    <div class="label"><mat-icon>ad_units</mat-icon> {{'COMMON.STATUT'| translate}} :</div>
                    <p class="text-green" *ngIf="project.status === 'Terminé' || project.status === 'Correction terminée'">{{ 'COMMON.' + project.status | translate}}
                        <button class="ml-1 primary-mini-btn" (click)="openRetourDialog(project)"
                                *ngIf="(user?.role === 'su' || user?.role === 'user')"> {{'COMMON.BOUTON_RETOUR'| translate}}  </button>
                    </p>
                    <p class="text-yellow" *ngIf="project.status !== 'Terminé' && project.status !== 'Correction terminée'">{{ 'COMMON.' + project.status | translate}}</p>
                </div>
                <div class="info-item">
                    <div class="label"><mat-icon>fireplace</mat-icon> {{'COMMON.PRODUCT_TYPE'| translate}} :</div>
                    <p>{{project.type}}</p>
                </div>
                <div class="info-item">
                    <div class="label"><mat-icon>ad_units</mat-icon> {{'COMMON.REFERENCE'| translate}} :</div>
                    <p>{{project.reference}}</p>
                </div>
                <div class="info-item" *ngIf="project.modelFoyer">
                    <div class="label"><mat-icon>roofing</mat-icon> {{'COMMON.ROOFING'| translate}} :</div>
                    <p>{{project.modelFoyer}}</p>
                </div>
                <div class="info-item">
                    <div class="label"><mat-icon>roofing</mat-icon> {{'COMMON.ROOM'| translate}} :</div>
                    <p>{{project.typePiece}}</p>
                </div>
                <div class="info-item">
                    <div class="label"><mat-icon>add_task</mat-icon> {{'COMMON.STYLE'| translate}} :</div>
                    <p>{{project.style}}</p>
                </div>
                <div class="info-item">
                    <div class="label"><mat-icon>description</mat-icon> {{'COMMON.DESCRIPTION'| translate}} :</div>
                    <p>{{project.description}}</p>
                </div>
                <div class="info-item" *ngIf="project.type !== 'Cheminée'">
                  <div class="label"><mat-icon>description</mat-icon> {{'COMMON.TECHNICAL_DATA'| translate}} :</div>
                  <p>{{loadedProduct?.donneesTechniquesCheminee || '-'}}</p>
                </div>
            </div>
            <div class="col-7">
                <div class="container">
                    <div class="row">
                      <!-- Données Technique cheminée: -->
                        <!-- <div class="col-12" *ngIf="project.type !== 'Poêle'">
                            <div class="info-item">
                                <div class="label"><mat-icon>description</mat-icon> Données techniques cheminée:</div>
                                <p>{{loadedProduct?.donneesTechniquesCheminee || '-'}}</p>
                            </div>
                        </div> -->
                      <div class="col-12" *ngIf="project.type === 'Cheminée'">
                        <div class="info-item">
                          <div class="label"><mat-icon>description</mat-icon> {{'COMMON.TECHNICAL_DATA'| translate}} :</div>
                          <p>{{loadedProduct?.donneesTechniquesFoyer || '-'}}</p>
                        </div>
                      </div>
                        <div class="col-12">
                            <div class="info-item">
                                <div class="label"><mat-icon>color_lens</mat-icon> {{'COMMON.COLORIS'| translate}} :</div>
                              <div>
                                <img src="" alt="">
                              </div>
                              <div class="float-left mr-2" *ngIf="project.picto">
                                <img [src]="'data:image/png;base64,' + project.picto" alt="">
                              </div>
                                <p>{{project.coloris || '-'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6" *ngIf="project.type !== 'Cheminée'">
                            <div class="image-item">
                                <div class="label">{{'COMMON.PICTURE_PRODUCT'| translate}}</div>
                                <img [src]="getPublic(project.photoProduit)" alt="" *ngIf="project.photoProduit">
                                <img [src]="'data:image/png;base64,' + loadedProduct?.visuel" alt="" class="preview product-picture" *ngIf="!project.photoProduit && loadedProduct && project.reference">

                            </div>
                        </div>
                        <div class="col-6" *ngIf="project.type === 'Cheminée'">
                            <div class="image-item">
                                <div class="label">{{'COMMON.PICTURE_MODEL_FIREPLACE'| translate}}</div>
                                <img [src]="getPublic(project.photoModeleCheminee)" alt="" *ngIf="project.photoModeleCheminee">
                                <img [src]="'data:image/png;base64,' + loadedProduct?.visuel" alt="" class="preview product-picture" *ngIf="!project.photoModeleCheminee && loadedProduct && project.reference">

                            </div>
                        </div>
                        <div class="col-6" *ngIf="project.type === 'Cheminée'">
                            <div class="image-item">
                                <div class="label">{{'COMMON.PICTURE_MODEL_ROOFING'| translate}}</div>
                                <img [src]="getPublic(project.photoModelFoyer)" alt="" *ngIf="project.photoModelFoyer">
                                <img [src]="'data:image/png;base64,' + imageFoyer" alt="" class="preview product-picture" *ngIf="!project.photoModelFoyer && loadedProduct && project.reference">
                            </div>
                        </div>
                        <div class="col-6">
<!--                            <div class="image-item" *ngIf="getExtension(project.planPiece) !== 'pdf'">-->
<!--                                <div class="label">{{'COMMON.ROOM_PLAN'| translate}}</div>-->
<!--                              <a [href]="getPublic(project.planPiece)" target="_blank">-->
<!--                                <img [src]="getPublic(project.planPiece)" alt=""/>-->
<!--                              </a>-->
<!--                            </div>-->

                          <div class="image-item"  *ngIf="project.planPiece">
<!--                            <div class="image-item"  *ngIf="!(getExtension(project.planPiece) !== 'pdf')">-->
                                <div class="label">{{'COMMON.ROOM_PLAN'| translate}}</div>
                                <a [href]="getPublic(project.planPiece)" target="_blank" class="result">
                                  <mat-icon>attach_file</mat-icon>
                                  {{'COMMON.OPEN' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="image-item">
                        <div class="label">{{'COMMON.ROOM_PICTURE'| translate}}</div>
                        <div class="photo-piece">
                          <a [href]="getPublic(project.photoPieceExistant1)" target="_blank" class="result" *ngIf="project.photoPieceExistant1">
                            <mat-icon>attach_file</mat-icon>
                            {{'COMMON.OPEN' | translate}}
                          </a>
                          <a [href]="getPublic(project.photoPieceExistant2)" target="_blank" class="result" *ngIf="project.photoPieceExistant2">
                            <mat-icon>attach_file</mat-icon>
                            {{'COMMON.OPEN' | translate}}
                          </a>
                          <a [href]="getPublic(project.photoPieceExistant3)" target="_blank" class="result" *ngIf="project.photoPieceExistant3">
                            <mat-icon>attach_file</mat-icon>
                            {{'COMMON.OPEN' | translate}}
                          </a>
                        </div>
<!--                        <img [src]="getPublic(project.photoPieceExistant1)" alt="" *ngIf="project.photoPieceExistant1">-->
<!--                        <img [src]="getPublic(project.photoPieceExistant2)" alt="" *ngIf="project.photoPieceExistant2">-->
<!--                        <img [src]="getPublic(project.photoPieceExistant3)" alt="" *ngIf="project.photoPieceExistant3">-->
                    </div>
                    <div class="image-item" *ngIf="project.result" >
                        <div class="label">Pdf de l'objet</div>
                        <a class="result" [href]="getPublic(project.result)" target="_blank">
                          <mat-icon>attach_file</mat-icon>
                          {{'COMMON.OPEN' | translate}}
                        </a>
                    </div>
                    <div class="updloads" *ngIf="user?.role == 'pimper'">
                        <div class="label">{{'COMMON.UPLOAD_OBJECT'| translate}}</div>
                        <div class="filename">{{resultInput.value}}</div>
                        <button type="button" mat-raised-button (click)="resultInput.click()">{{'COMMON.BROWSE'| translate}}...</button>
                        <input hidden (change)="onFileSelected(resultInput)" #resultInput type="file"
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">

            <div class="col-12 text-center" *ngIf="!loading">
                <button mat-raised-button color="primary" (click)="submitForm()" *ngIf="user?.role === 'pimper' || user?.role === 'admin'">{{'COMMON.DONE'| translate}}</button>
                <button mat-raised-button class="ml-3" color="dark" (click)="exportPDF()" *ngIf="user?.role == 'pimper'">{{'COMMON.EXPORT_PDF'| translate}}</button>
            </div>
            <div class="col-12 text-center">
                <mat-spinner diameter="40" class="mt-2 mr-auto ml-auto" *ngIf="loading"></mat-spinner>
            </div>
        </div>
    </div>
</div>

<swal #confirmSwal icon="success"></swal>
