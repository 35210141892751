<div class="container" *ngIf="user">
    <div class="row">
        <mat-toolbar class="toolbar">
            <a class="ml-1 nav-bar-brand">
                <img src="assets/img/logo-seguin-small.png">
                <app-select-language></app-select-language>
            </a>
            <span class="spacer"></span>
            <button mat-button (click)="goTo('/user-interface/list')" *ngIf="user.role === 'user'">
                <mat-icon>admin_panel_settings</mat-icon>
                {{"COMMON.PROJECTS_LIST" | translate}}
            </button>
            <button mat-button (click)="goTo('/user-interface/new')" *ngIf="user.role === 'user' || user.role === 'su'">
                <mat-icon>add</mat-icon>
                {{"COMMON.NEW_PROJECT" | translate}}
            </button>

            <ngx-avatar [name]="user.firstName + ' ' + user.lastName?.split(' ')[0]" class="mr-2 pointer">
            </ngx-avatar>
            <span class="user-name pointer">{{user.firstName.split(' ')[0]}}</span>

            <button mat-icon-button class="ml-1" (click)="canILogout()" matTooltip="Se déconnecter">
                <mat-icon class="deconect">power_settings_new</mat-icon>
            </button>
        </mat-toolbar>
    </div>
</div>


<swal #confirmSwal [title]="translatePipe.transform('COMMON.LOGOUT')" icon="question" [showCancelButton]="true" [reverseButtons]="true"
    [confirmButtonText]="translatePipe.transform('COMMON.CONFIRM')" [cancelButtonText]="translatePipe.transform('COMMON.REFUSE')" (confirm)="logout()">
</swal>
