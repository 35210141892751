import { Component } from '@angular/core';
import {Store} from '@ngrx/store';
import {UserState} from './@core/store/user/user.state';
import {checkUserLoginSuccess, logoutUser} from './@core/store/user/user.actions';
import {User} from './@core/store/user/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'seguin';

  constructor(
    private store: Store<{user: UserState}>
  ) {
    let localUser: any = localStorage.getItem('seguin_user');
    if (localUser) {
      localUser = JSON.parse(localUser);
      console.log("Local user", localUser)
      this.store.dispatch(checkUserLoginSuccess({user: localUser, preventRedirect: true}));
    } else {
      this.store.dispatch(logoutUser());
    }
  }
}


