import { User } from './user.model';

export interface UserState {
    actual: User;
    selected: User;
    list: User[];
    loading: boolean;
    error: string;
}

export const initialUserState = {
    actual: null,
    list: [],
    loading: false,
    error: null,
    selected: null
};
