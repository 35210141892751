import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: any = localStorage.getItem('seguin_user');
    if (!user || JSON.parse(user).role !== 'admin') {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}

@Injectable({
  providedIn: 'root'
})
export class IsNotLoggedInGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: any = localStorage.getItem('seguin_user');
    if (user && user._id) {
      this.router.navigate(['/user-interface']);
      return false;
    }
    return true;
  }

}

@Injectable({
  providedIn: 'root'
})
export class IsPimperGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: any = localStorage.getItem('seguin_user');
    if (!user || JSON.parse(user).role !== 'pimper') {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}

@Injectable({
  providedIn: 'root'
})
export class IsUserGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: any = localStorage.getItem('seguin_user');
    if (!user ||( JSON.parse(user).role !== 'user' && JSON.parse(user).role !== 'su')) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}
