import { createReducer, on, Action } from '@ngrx/store';
import { initialProjectState, ProjectState } from './project.state';
import * as ProjectActions from './project.actions';

const reducer = createReducer(
    initialProjectState,
    on(
        ProjectActions.createProjectSuccess,
        (state, {project}) => {
            return {
                ...state,
                selected: project,
                loading: false,
                error: null
            };
        }
    ),
    on(
        ProjectActions.updateProjectSuccess,
        (state, {project}) => {
            return {
                ...state,
                selected: project,
                loading: false,
                error: null
            };
        }
    ),
    on(
        ProjectActions.getAllProjectSuccess,
        (state, {projects}) => {
            return {
                ...state,
                list: projects,
                loading: false,
                error: null
            };
        }
    ),
    on(
        ProjectActions.startLoading,
        (state) => {
            return {
                ...state,
                loading: true
            };
        }
    ),
    on(
        ProjectActions.loadChemineSuccess,
        (state, {data}) => {
            return {
                ...state,
                chemineList: data
            };
        }
    ),
    on(
        ProjectActions.loadFoyerSuccess,
        (state, {data}) => {
            return {
                ...state,
                foyerList: data
            };
        }
    ),
    on(
        ProjectActions.loadPoelesSuccess,
        (state, {data}) => {
            return {
                ...state,
                poelesList: data
            };
        }
    ),
    on(
        ProjectActions.loadJcbordeletSuccess,
        (state, {data}) => {
            return {
                ...state,
                jcBordeletList: data
            };
        }
    ),
);

// tslint:disable-next-line:typedef
export function projectReducer(state: ProjectState|undefined, action: Action) {
    return reducer(state, action);
}
