import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RequestService } from 'src/app/@core/services/request/request.service';
import { updateProject, getAllProject, uploadResult, startLoading } from 'src/app/@core/store/project/project.actions';
import {selectProjectById, selectProjectLoading} from 'src/app/@core/store/project/project.selector';
import { ProjectState } from 'src/app/@core/store/project/project.state';
import { getAllUser } from 'src/app/@core/store/user/user.actions';
import {selectActualUser, selectSelectedUser, selectUsersList} from 'src/app/@core/store/user/user.selectors';
import { RetourModalComponent } from '../retour-modal/retour-modal.component';
import { jsPDF } from 'jspdf';
import {SwalComponent} from "@sweetalert2/ngx-sweetalert2";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {

  loading: boolean;
  public apiUrl = 'https://seguin.pimplibrary.fr';
  // public apiUrl = 'https://5.196.83.91:3015';
  loadedProduct: any = {};
  imageFoyer: any;
  project: any = {pimper: {_id: ''}};

  file: any;
  user: any;
  waitingRes: boolean = false;
  pimpers: any[] = [];
  public lineHeightIncrement = 30;
  public lineHeight = 0;
  public doc;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;

  constructor(
    private store: Store<{project: ProjectState}>,
    private requestService: RequestService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {

    this.store.dispatch(getAllProject());
    this.store.dispatch(getAllUser());
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.store.select(selectSelectedUser).subscribe(u => {
      this.user = u;
      console.log('User', u);
    })
    this.store.select(selectUsersList).pipe().subscribe(value => {
      console.log('User list', value);
      if (value) {
        this.pimpers = value.filter(u => u.role === 'pimper');
      }
    });
    this.route.params.subscribe(params => {
      if (params.id) {
        this.store.select(selectProjectById(params.id)).subscribe(value => {
          if (value) {
            this.project = {
              ...value,
              pimper : value.pimper ? {...value.pimper} : {_id: ''}
            }
            console.log('Project', value);
            const projectReference = this.project.reference.split(' - ')[0] + ' - ' + this.project.idDossier;
            const foyerReference = this.project.modelFoyer.split(' - ')[0] + ' - ' + this.project.idDossierFoyer;
            this.loadElement(projectReference);
            this.loadFoyer(foyerReference);
          }
        })
      }
    })
    this.store.select(selectProjectLoading).pipe().subscribe(value => {
      this.loading = value;
      console.log('lOADINGGGG', value);
      if (!value && this.waitingRes) {
        this.store.dispatch(getAllProject());
        this.confirmSwal.fire();
      }
    })
  }

  submitForm() {
    if (this.user && this.user.role === 'pimper') {
      const formData = new FormData();
      formData.append('file', this.file)
      this.waitingRes = true;
      this.store.dispatch(startLoading())
      this.store.dispatch(uploadResult({project: this.project, formData}))
    } else {
      
      this.store.dispatch(startLoading())
      this.project.status = this.project.pimper ? 'En cours': 'En attente';
      const project = {...this.project, pimper: this.project.pimper._id};
      console.log('Project', project);
      this.store.dispatch(updateProject({projectId: this.project._id, data: this.project}))
    }
  }

  getExtension(path: string) {
    return path && path.split('.').pop();
  }

  getPublic(url) {
    return `${this.apiUrl}/${url}`;
  }

  loadElement(code) {
    if (code) {
      const [CodeProduit, IdDossier] = code.split(' - ')
      console.log('load Element code:', code)
      console.log({CodeProduit, IdDossier})
      this.requestService._getProductInfo({CodeProduit, IdDossier}).then(res => {
        console.log(res);
        Object.assign(this.loadedProduct, {
          ...res,
          donneesTechniquesCheminee: res.donneesTechniques
        })
        console.log(this.loadedProduct);
      })
    }

  }

  loadFoyer(code) {
    if (code) {
      const [CodeProduit, IdDossier] = code.split(' - ')
      console.log('load Foyer code:', code)
      console.log({CodeProduit, IdDossier})
      this.requestService._getProductInfo({CodeProduit, IdDossier}).then(res => {
        console.log(res)
        Object.assign(this.loadedProduct, {donneesTechniquesFoyer: res.donneesTechniques})
        this.imageFoyer = res.visuel;
      })
    }
  }

  openRetourDialog(project) {
    const dialogRef = this.dialog.open(RetourModalComponent, {
      data: {edit: true, project },
      disableClose: true,
      maxHeight: '95vh'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log(result);
      this.store.dispatch(getAllProject())
    });
  }

  onFileSelected(inputNode) {
    if (typeof (FileReader) !== 'undefined') {
      inputNode.filename = inputNode.files[0].name;
      this.file = inputNode.files[0];
    }
  }


  async exportPDF() {
    this.doc = new jsPDF('p', 'pt', 'a4');

    const logoImgData = await this.getImageFromURL('http://seguin.pimplibrary.fr/assets/img/logo-seguin-small.png');
    this.lineHeight = 80;
    this.doc.addImage(logoImgData, 'png', 20, 20, 200, 40);

    this
      .docTextKeyValue('ID', this.project._id)
      .docTextKeyValue('Statut', this.project.status)
      .docTextKeyValue('Type du produit', this.project.type)
      .docTextKeyValue('Référence', this.project.reference)
      .docTextKeyValue('Modèle foyer', this.project.modelFoyer)
      .docTextKeyValue('Pièce', this.project.typePiece)
      .docTextKeyValue('Style', this.project.style)
      .docTextKeyValue('Description', this.project.description);

    if (this.project.type !== 'Cheminée') {
      this.docTextKeyValue('Données techniques', this.loadedProduct.donneesTechniquesCheminee || '-');
    }  else {
      this.docTextKeyValue('Données techniques', this.loadedProduct.donneesTechniquesFoyer || '-');
    }

    this.docTextKeyValue('Coloris', `${this.project.coloris || '-'}`);
    if (this.project.picto) {
      this.doc.addImage(this.project.picto, 'png', 40, this.lineHeight + 20, 100, 100);
    }

    if (this.project.type === 'Cheminée') {
      this.doc
        .setTextColor(100, 100, 100)
        .text(`Modèle cheminée`, 40, this.lineHeight + 150)
        .setTextColor(0, 0, 0);

      if (this.project.photoModeleCheminee) {
        this.doc.addImage(this.getPublic(this.project.photoModeleCheminee), 'png', 40, this.lineHeight + 160, 100, 100);
      }else {
        if (this.loadedProduct.visuel) {
          this.doc.addImage(this.loadedProduct.visuel, 'png', 40, this.lineHeight + 160, 100, 100);
        }
      }

      this.doc
        .setTextColor(100, 100, 100)
        .text(`Modèle foyer`, 200, this.lineHeight + 150)
        .setTextColor(0, 0, 0);

      if (this.project.photoModelFoyer) {
        this.doc.addImage(this.getPublic(this.project.photoModelFoyer), 'png', 200, this.lineHeight + 160, 100, 100);
      }else if (this.imageFoyer){
        this.doc.addImage(this.imageFoyer, 'png', 200, this.lineHeight + 160, 100, 100);
      }
    }


    this.doc
      .setTextColor(100, 100, 100)
      .text(`Plan pièce`, 40, this.lineHeight + 290)
      .setTextColor(0, 0, 0);

    if (this.project.planPiece) {
      this.doc
        .textWithLink(this.project.planPiece.replace('public/', ''), 40, this.lineHeight + 310,
          { url: this.getPublic(this.project.planPiece) });
    }

    this.doc.save('test.pdf');
  }

  getImageFromURL(urlImage) {
    return new Promise((resolve) => {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous'); // getting images from external domain

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;

        // next three lines for white background in case png has a transparent background
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = '#fff';  /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext('2d').drawImage(image, 0, 0);

        resolve(canvas.toDataURL('image/jpeg'));
      };

      image.src = urlImage;
    });
  }

  docTextKeyValue(key: string, value: any) {
    const lineHeight = (this.lineHeight || 0) + this.lineHeightIncrement;
    const keyPosX = 30;
    const valuePosX = keyPosX + ((key.length * 8) + 15);
    const splitText = this.doc.splitTextToSize(value, 380);

    this.doc
      .setTextColor(100, 100, 100)
      .text(`${key} :`, keyPosX, lineHeight)
      .setTextColor(0, 0, 0)
      .text(valuePosX, lineHeight, splitText);

    this.lineHeight += splitText.length * this.lineHeightIncrement;
    return this;
  }

}
