import {createAction, props} from '@ngrx/store';
import { User } from './user.model';

export enum UserActionsTypes {
    GET_ALL = '[User] Get All User',
    GET_ALL_SUCCESS = '[User] Get All User success',

    GET = '[User] Get User',
    GET_SUCCESS = '[User] Get User success',

    CHECK_LOGIN = '[User] Check user login',
    CHECK_SEGUIN_LOGIN = '[User] Check seguin login',
    CHECK_LOGIN_SUCCESS = '[User] Check user login success',

    CREATE = '[User] Create User',
    CREATE_SUCCESS = '[User] Create User success',

    UPDATE = '[User] Update User',
    UPDATE_SUCCESS = '[User] Update User success',

    START_LOADING = '[User] Start Loading',
    STOP_LOADING = '[User] Stop Loading',

    LOGOUT = '[User] Logout User',

    ERROR = '[User] Error'
}

export const getAllUser = createAction(
    UserActionsTypes.GET_ALL,
);

export const getAllUserSuccess = createAction(
    UserActionsTypes.GET_ALL_SUCCESS,
    props<{users: User[]}>()
);

export const getUser = createAction(
    UserActionsTypes.GET,
    props<{userId: string}>()
);

export const getUserSuccess = createAction(
    UserActionsTypes.GET_SUCCESS,
    props<{user: User}>()
);

export const createUser = createAction(
    UserActionsTypes.CREATE,
    props<{user: User, account: Account}>()
);

export const createUserSuccess = createAction(
    UserActionsTypes.CREATE_SUCCESS,
    props<{user: User}>()
);

export const updateUser = createAction(
    UserActionsTypes.UPDATE,
    props<{userId: string; data: User|any}>()
);

export const updateUserSuccess = createAction(
    UserActionsTypes.UPDATE_SUCCESS,
    props<{user: User}>()
);

export const raiseError = createAction(
    UserActionsTypes.ERROR,
    props<{error}>()
);

export const checkUserLogin = createAction(
    UserActionsTypes.CHECK_LOGIN,
    props<{email: string, password: string}>()
);

export const checkSeguinLogin = createAction(
    UserActionsTypes.CHECK_SEGUIN_LOGIN,
    props<{email: string, password: string}>()
);

export const checkUserLoginSuccess = createAction(
    UserActionsTypes.CHECK_LOGIN_SUCCESS,
    props<{user: User, preventRedirect?: boolean}>()
);

export const logoutUser = createAction(
    UserActionsTypes.LOGOUT
);

export const startLoading = createAction(
    UserActionsTypes.START_LOADING
);

export const stopLoading = createAction(
    UserActionsTypes.STOP_LOADING
);