<div id="layout-login" class="container-fluid h-100">
  <div class="container h-100">
    <div class="row align-items-center h-100">
      <div class="blockLeft col-md-7">
        <h3 >{{'LOGIN.WELCOME' | translate}},</h3>
        <p>{{'LOGIN.P1'| translate}}</p>
        <p>{{'LOGIN.P2'| translate}}</p>
      </div>
      <div class="blockRight col-md-5">
        <div class="d-flex"><span class="mr-auto"></span><app-select-language></app-select-language></div>
        <img id="logo" class="img-fluid" src="assets/img/logo-seguin-big.png">
        <div class="form-container">
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{"COMMON.USERNAME" | translate}}</mat-label>
                <mat-icon matPrefix>mail_outline</mat-icon>
                <input matInput placeholder="{{'COMMON.USERNAME' | translate}}" formControlName="email">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{"COMMON.PASSWORD" | translate}}</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <input matInput placeholder="Mot de passe" type="password" formControlName="password">
              </mat-form-field>
            </div>
            <div>
                <mat-spinner diameter="35" *ngIf="loading"></mat-spinner>
                <button mat-raised-button class="btn-submit" [disabled]="!userForm.valid" *ngIf="!loading">{{"COMMON.LOGIN" | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
