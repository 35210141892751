import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { checkSeguinLogin, startLoading } from 'src/app/@core/store/user/user.actions';
import { checkUserLogin } from 'src/app/@core/store/user/user.actions';
import { selectUserLoading } from 'src/app/@core/store/user/user.selectors';
import { UserState } from 'src/app/@core/store/user/user.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userForm: FormGroup;
  loading: boolean;

  constructor(
    private fb: FormBuilder,
    private store: Store<{user: UserState}>
  ) {
    this.userForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.store.select(selectUserLoading).pipe().subscribe(value => {
      this.loading = value;
    });
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.store.dispatch(startLoading());
    this.store.dispatch(checkSeguinLogin(this.userForm.value));
  }

}
