import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AvatarModule } from 'ngx-avatar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { RetourModalComponent } from './retour-modal/retour-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserModalComponent } from './user-modal/user-modal.component';
import { SelectLanguageComponent } from './select-language/select-language.component';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import {MatSelectModule} from '@angular/material/select';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [ToolbarComponent, RetourModalComponent, UserModalComponent, SelectLanguageComponent, ProjectDetailsComponent],
    imports: [
        CommonModule,
        AvatarModule,
        SweetAlert2Module.forChild(),
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatDialogModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatSelectModule
    ],
  providers: [TranslatePipe],
  entryComponents: [RetourModalComponent, UserModalComponent, SelectLanguageComponent],
  exports: [ToolbarComponent, RetourModalComponent, UserModalComponent, SelectLanguageComponent, TranslateModule, ProjectDetailsComponent]
})
export class SharedModule { }
