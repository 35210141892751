import { Project } from './project.model';

export interface ProjectState {
    selected: Project;
    list: Project[];
    loading: boolean;
    error: string;
    chemineList: any[];
    foyerList: any[];
    poelesList: any[];
    jcBordeletList: any[];
}

export const initialProjectState = {
    list: [],
    loading: false,
    error: null,
    selected: null,
    chemineList: [],
    foyerList: [],
    poelesList: [],
    jcBordeletList: []
};
