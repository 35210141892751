import { AppState } from '../app.state';
import { UserState } from './user.state';
import { createSelector } from '@ngrx/store';

const selectUser = (state: AppState) => state.user;

export const selectActualUser = createSelector(
    selectUser,
    (state: UserState) => state.actual
);

export const selectSelectedUser = createSelector(
    selectUser,
    (state: UserState) => state.selected
);

export const selectUsersList = createSelector(
    selectUser,
    (state: UserState) => state.list
);

export const selectUserError = createSelector(
    selectUser,
    (state: UserState) => state.error
);

export const selectUserLoading = createSelector(
    selectUser,
    (state: UserState) => state.loading
);
