import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { makeRetourProject, startLoading } from 'src/app/@core/store/project/project.actions';
import { selectProjectLoading } from 'src/app/@core/store/project/project.selector';
import { ProjectState } from 'src/app/@core/store/project/project.state';
import { selectSelectedUser } from 'src/app/@core/store/user/user.selectors';
import { UserState } from 'src/app/@core/store/user/user.state';
import { ProjectModalComponent } from 'src/app/pages/user-interface/project-modal/project-modal.component';

@Component({
  selector: 'app-retour-modal',
  templateUrl: './retour-modal.component.html',
  styleUrls: ['./retour-modal.component.scss']
})
export class RetourModalComponent implements OnInit {
  retourForm: FormGroup;
  retourFile: any = [null, null, null];
  loading = false;
  user: any;

  public apiUrl = 'https://seguin.pimplibrary.fr';
  closeAfterLoading = false;

  constructor(
    private dialogRef: MatDialogRef<ProjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<{project: ProjectState, user: UserState}>
  ) {
    this.retourForm = this.fb.group({
      remarque: ['', Validators.required]
    });

    this.store.select(selectSelectedUser).pipe().subscribe(value => {
      this.user = value;
    })

    this.store.select(selectProjectLoading).pipe().subscribe(value => {
      this.loading = value;
      if (this.loading === false && this.closeAfterLoading) {
        this.dialogRef.close();
        this.data.dialogParentClose();
      }
    })

  }

  ngOnInit(): void {
    console.log(this.data)
    this.retourForm = this.fb.group({
      remarque: [this.data.project.remarque, Validators.required]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileSelected(inputNode, fileIndex): void {
    if (typeof (FileReader) !== 'undefined') {
      inputNode.filename = inputNode.files[0].name;
      this.retourFile[fileIndex] = inputNode.files[0];
    }
  }

  onSubmit(){
    const project: any = {...this.retourForm.value, status: 'En cours de correction', _id: this.data.project._id};
    let formData;
    if (this.retourFile) {
      formData = new FormData()
      let i = 0;
      for(let f of this.retourFile.filter(el => el)) {
        formData.append('remarqueFile' + (i || ''), f);
        i++;
      }
    }

    console.log('formData', formData, 'project', project)
    this.store.dispatch(startLoading());
    this.closeAfterLoading = true;
    this.store.dispatch(makeRetourProject({formData, project}));
  }


  getPublic(url) {
    return `${this.apiUrl}/${url}`;
  }

}
