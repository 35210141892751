import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit {

  lang = localStorage.getItem('lang') || 'fr';
  langLabel;

  constructor(private translate: TranslateService) {
    translate.setDefaultLang(this.lang);
  }

  ngOnInit(): void {
    this.getLangLabel();
  }

  setLang(lang: 'fr' | 'en'): void {
      this.lang = lang;
      this.translate.use(lang);
      localStorage.setItem('lang', lang);
      this.getLangLabel();
  }

  getLangLabel(): void {
    switch (this.lang) {
      case 'fr':
        this.langLabel = 'Français';
        break;
      case 'en':
        this.langLabel = 'English';
        break;
    }
  }
}
