<div class="w-card">
    <div class="w-card-header text-center back-red-title" matDialogTitle>
        <mat-icon>person</mat-icon> {{ "SHARED_COMPONENT.USER_MODAL.TITLE" | translate}}
    </div>
    <div class="w-card-body">
        <div class="row">
            <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{"SHARED_COMPONENT.USER_MODAL.LABEL_1" | translate}}</mat-label>
                        <input type="text" [readonly]="true" matInput  formControlName="firstName">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{"SHARED_COMPONENT.USER_MODAL.LABEL_2" | translate}}</mat-label>
                        <input type="text" [readonly]="true" matInput  formControlName="codeRevendeur">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{"COMMON.ADDRESS_EMAIL" | translate}}</mat-label>
                        <input type="text" [readonly]="!data.edit" matInput  formControlName="email">
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="row mt-4" *ngIf="!loading">
            <div class="col-6 text-center m-auto">
                <button mat-raised-button color="warn" (click)="closeDialog()">{{"COMMON.CLOSE" | translate}}</button>
            </div>
            <div class="col-6 text-center m-auto" *ngIf="data?.edit">
                <button mat-raised-button color="primary" [disabled]="!userForm.valid" *ngIf="!loading"
                    (click)="onSubmit()">{{"COMMON.DONE" | translate}}</button>
            </div>
        </div>
        <div class="row mt-4 text-center" *ngIf="loading">
            <mat-spinner diameter="35" *ngIf="loading"></mat-spinner>
        </div>
    </div>
</div>