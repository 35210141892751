import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { startLoading, updateUser } from 'src/app/@core/store/user/user.actions';
import { selectUserLoading } from 'src/app/@core/store/user/user.selectors';
import { UserState } from 'src/app/@core/store/user/user.state';
@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  public apiUrl = 'https://seguin.pimplibrary.fr';
  closeAfterLoading = false;
  loading: boolean;
  userForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<{ user: UserState}>

  ) {
    this.store.select(selectUserLoading).pipe().subscribe(value => {
      this.loading = value;
    })
  }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      firstName: [this.data.user.firstName, Validators.required],
      email: [this.data.user.email, Validators.required],
      codeRevendeur: [this.data.user.codeRevendeur, Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }


  onSubmit(){
    this.store.dispatch(startLoading());
    console.log(this.data.user._id)
    this.store.dispatch(updateUser({userId: this.data.user._id, data: {email: this.userForm.value.email}}))
  }


  getPublic(url) {
    return `${this.apiUrl}/${url}`;
  }

}
