import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { logoutUser } from 'src/app/@core/store/user/user.actions';
import { selectActualUser, selectSelectedUser } from 'src/app/@core/store/user/user.selectors';
import { UserState } from 'src/app/@core/store/user/user.state';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
  user: any;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  constructor(
    private store: Store<{ user: UserState }>,
    private router: Router,
    public translatePipe: TranslatePipe
  ) {
    this.store.select(selectSelectedUser).pipe().subscribe(value => {
      this.user = value;
    })
  }

  ngOnInit(): void {
  }
  canILogout() {
    this.confirmSwal.fire();
  }
  goTo(link) {
    this.router.navigate([link]);
  }

  logout() {
    localStorage.removeItem('seguin_user')
    this.store.dispatch(logoutUser());
  }
}
