import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { UserState } from './user.state';
import * as UserActions from './user.actions';
import * as ProjectActions from './../project/project.actions';
import { switchMap, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RequestService } from '../../services/request/request.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserEffects {

    @Effect()
    Create$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.CREATE),
        switchMap(({ user, account }) => {
            return this.requestService.post('users', { user, account }).pipe(
                map(res => {
                    console.log('res', res);
                    if (res.success) {
                        return UserActions.createUserSuccess({ user: res.user });
                    } else {
                        return UserActions.raiseError({ error: res.error });
                    }
                })
            );
        })
    );

    
    @Effect()
    CheckSeguinLogin$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.CHECK_SEGUIN_LOGIN),
        switchMap((payload: any) => {
            const body = {
                username: payload.email,
                pwd: payload.password
            }
            console.log(body);
            return this.requestService._post('seguin', body, 'login').pipe(
                map(res => {
                    console.log('res', res);
                    if (!res.error) {
                        return UserActions.checkUserLoginSuccess({ user: res.data });
                    } else {
                        return UserActions.checkUserLogin(payload);
                    }
                })
            );
        })
    );
    @Effect()
    CheckLogin$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.CHECK_LOGIN),
        switchMap((payload) => {
            return this.requestService.post('users', payload, 'login').pipe(
                map(res => {
                    console.log('res', res);
                    if (!res.error) {
                        return UserActions.checkUserLoginSuccess({ user: res.user });
                    } else {
                        return UserActions.raiseError({ error: 'Login invalide' });
                    }
                })
            );
        })
    );

    @Effect({ dispatch: false })
    CheckLoginSuccess$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.CHECK_LOGIN_SUCCESS),
        tap(({ user, preventRedirect }) => {

            localStorage.setItem('seguin_user', JSON.stringify(user));
            if (!preventRedirect) {
                switch ((user as any).role) {
                    case 'admin':
                        this.router.navigate(['/admin-interface']);
                        break;
                    case 'user':
                    case 'su':
                        this.router.navigate(['/user-interface/list']);
                        break;
                    case 'pimper':
                        this.router.navigate(['/pimper-interface']);
                        break;
                }
            }
            
        })
    );

    @Effect()
    Update$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.UPDATE),
        switchMap(({ userId, data }) => {
            console.log(data)
            return this.requestService.put('users', data , `${userId}`).pipe(
                map(res => {
                    if (!res.error) {
                        this.snackbar.open('Modification effectuée', 'OK')
                        this.store.dispatch(ProjectActions.getAllProject())
                        return UserActions.updateUserSuccess({ user: res.user });
                    } else {
                        console.log(res)
                        return UserActions.raiseError({ error: res.error });
                    }
                })
            );
        })
    );

    @Effect()
    Get$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.GET),
        switchMap(({ userId }): any => {
            if (userId) {
                // console.log("iiiiiiiiiiiii", userId)
                return this.requestService.get('users', userId).pipe(
                    map(res => {
                        if (res.success) {
                            return UserActions.getUserSuccess({ user: res.user });
                        } else {
                            return UserActions.raiseError({ error: res.error });
                        }
                    })
                );
            }
            else {
                return UserActions.stopLoading()
            }
        })
    );

    @Effect()
    GetAll$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.GET_ALL),
        switchMap(({ userId }) => {
            return this.requestService.get('users').pipe(
                map(res => {
                    console.log(res);
                    if (res.success) {
                        return UserActions.getAllUserSuccess({ users: res.users });
                    } else {
                        return UserActions.raiseError({ error: res.error });
                    }
                })
            );
        })
    );

    @Effect({ dispatch: false })
    Logout$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.LOGOUT),
        tap(() => {
            localStorage.removeItem('seguin_user');
            localStorage.removeItem('seguin_user_token');
            this.router.navigate(['/login']);
        })
    );

    @Effect({ dispatch: false })
    Error$ = this.action$.pipe(
        ofType(UserActions.UserActionsTypes.ERROR),
        tap(({ error }) => {
            this.snackbar.open(error, 'Erreur', { duration: 3000 });
        })
    );

    constructor(
        private action$: Actions,
        private requestService: RequestService,
        private router: Router,
        private snackbar: MatSnackBar,
        private store: Store
    ) { }
}
