import { AppState } from '../app.state';
import { ProjectState } from './project.state';
import { createSelector } from '@ngrx/store';

const selectProject = (state: AppState) => state.project;

export const selectSelectedProject = createSelector(
    selectProject,
    (state: ProjectState) => state.selected
);

export const selectProjectsList = createSelector(
    selectProject,
    (state: ProjectState) => state.list
);

export const selectProjectError = createSelector(
    selectProject,
    (state: ProjectState) => state.error
);

export const selectProjectById = (id) => createSelector(
    selectProjectsList,
    (data) => {
        if (data) {
            return data.find((e: any) => e._id === id);
        }
        return null;
    }
);

export const selectProjectLoading = createSelector(
    selectProject,
    (state: ProjectState) => state.loading
);


export const selectChemineList = createSelector(
    selectProject,
    (state: ProjectState) => state.chemineList
);

export const selectFoyerList = createSelector(
    selectProject,
    (state: ProjectState) => state.foyerList
);

export const selectPoelesList = createSelector(
    selectProject,
    (state: ProjectState) => state.poelesList
);

export const selectJcBordeletList = createSelector(
    selectProject,
    (state: ProjectState) => state.jcBordeletList
);
