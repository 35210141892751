import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import {IsAdminGuard, IsNotLoggedInGuard, IsPimperGuard, IsUserGuard} from "./@core/guards/is-admin.guard";

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [IsNotLoggedInGuard]},
  {path: 'user-interface', loadChildren: () => import('./pages/user-interface/user-interface.module').then(m => m.UserInterfaceModule), canActivate: [IsUserGuard]},
  {path: 'pimper-interface', loadChildren: () => import('./pages/pimper-interface/pimper-interface.module').then(m => m.PimperInterfaceModule), canActivate: [IsPimperGuard]},
  {path: 'admin-interface', loadChildren: () => import('./pages/admin-interface/admin-interface.module').then(m => m.AdminInterfaceModule), canActivate: [IsAdminGuard]},
  {path: 'su-interface', loadChildren: () => import('./pages/su-interface/su-interface.module').then(m => m.SuInterfaceModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
