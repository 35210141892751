import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as ProjectActions from './project.actions';
import { switchMap, map, tap, mergeMap } from 'rxjs/operators';
import { RequestService } from '../../services/request/request.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { mailHtml } from 'src/app/@shared/template.mail';
import { Store } from '@ngrx/store';
import { ProjectState } from './project.state';

@Injectable()
export class ProjectEffects {

    @Effect()
    Create$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.CREATE),
        switchMap(({project, formData, user}) => {
            console.log('formdata  ======>>>>>', formData);
            return this.requestService.post('upload', formData).pipe(
                map(res => {
                    console.log('res', res);
                    if (res.success) {
                        return ProjectActions.updateProject({
                          projectId: res.body._id,
                          data: {
                            ...(project as any),
                            isNew: true,
                            user,
                            calculateDelay: true
                          },
                          redirect: true
                        });
                    } else {
                        return ProjectActions.raiseError({error: res.error});
                    }
                })
            );
        })
    );

    @Effect()
    MakeRetour$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.MAKE_RETOUR),
        switchMap(({project, formData}) => {
            console.log('formdata======>>>>>', formData);
            if (formData) {
                const filename = 'remarque';
                // console.log(Array.from((formData as any).entries())[0] as any);
                console.log(filename);
                return this.requestService.post(`upload`, formData, `multiple/${(project as any)._id}`).pipe(
                    map(res => {
                        console.log('res', res);
                        if (res.success) {
                            return ProjectActions.updateProject({projectId: (project as any)._id, data: {...(project as any) }, redirect: true});
                        } else {
                            return ProjectActions.raiseError({error: res.error});
                        }
                    })
                );
            } else {
                console.log('Making retour')
                return of(ProjectActions.updateProject({projectId: (project as any)._id, data: project, redirect: true}));
            }
            // return of (ProjectActions.startLoading())
        })
    );

    @Effect()
    Update$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.UPDATE),
        switchMap(({projectId, data, redirect}) => {
            console.log('==== Project ', projectId, data);
            return this.requestService.put('projects', data, `${projectId}`, ).pipe(
                map(res => {
                    console.log('res', res);
                    if (res.success) {
                        if (redirect) {
                            this.router.navigate(['/user-interface/list']); // just pour enlever tout les dialogs
                        }
                        return ProjectActions.updateProjectSuccess({project: res.project});
                    } else {
                        return ProjectActions.raiseError({error: res.error});
                    }
                })
            );
        })
    );

    @Effect()
    Get$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.GET),
        switchMap(({projectId}) => {
            return this.requestService.get('projects', projectId).pipe(
                map(res => {
                    if (res.success) {
                        return ProjectActions.getProjectSuccess({project: res.project});
                    } else {
                        return ProjectActions.raiseError({error: res.error});
                    }
                })
            );
        })
    );

    @Effect()
    GetAll$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.GET_ALL),
        switchMap(({projectId}) => {
            let user: any = localStorage.getItem('seguin_user');
            let link: any;
            if (user) {
                user = JSON.parse(user);
                switch (user.role) {
                    case 'admin':
                    case 'master':
                        link = 'projects';
                        break;
                    case 'user':
                        link = 'projects/user/' + user._id;
                        break;
                    case 'su':
                        link = 'projects';
                        break;
                    case 'pimper':
                        link = 'projects/pimper/' + user._id;
                        break;
                }
            }
            return this.requestService.get(link).pipe(
                map(res => {
                    console.log(res);
                    if (res.success) {
                        return ProjectActions.getAllProjectSuccess({projects: res.body});
                    } else {
                        return ProjectActions.raiseError({error: res.error});
                    }
                })
            );
        })
    );

    @Effect()
    UploadResult$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.UPLOAD_RESULT),
        switchMap(({formData, project}) => {
            let projectId = (project as any)._id;
            console.log('formdata======>>>>>', formData);
            return this.requestService.post('upload', formData, `${projectId}?=result.pdf`).pipe(
                    switchMap(res => {
                        console.log('res', res);
                        if (res.success) {
                            let status = 'Terminé';
                            if ((project as any).remarque) {
                                status = 'Correction terminée';
                            }
                            return [
                                ProjectActions.sendMailNotification({
                                  userEmail: (project as any).user.email,
                                  project
                                }),
                                ProjectActions.updateProject({projectId, data: {status, result: 'public/' + projectId + '/result.pdf'}})
                            ];
                        } else {
                            return [ProjectActions.raiseError({error: res.error})];
                        }
                    })
            );
        })
    );

    @Effect({dispatch: false})
    SendMailNotification$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.SEND_MAIL_NOTIFICATION),
        tap(({userEmail, project}) => {
            console.log('sending mail')
            this.requestService._post('project-done', {
                userEmail,
                ...(project as any)
            }).pipe(
                map(res => {
                    console.log(res)
                })
            ).subscribe()
        })
    );

    @Effect()
    LoadChemine$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.LOAD_CHEMINE),
        switchMap(() => {
            return this.requestService._post('produits', {Type: '1'}).pipe(
                map(res => {
                    if (res) {
                        return ProjectActions.loadChemineSuccess({data: res});
                    }
                })
            );
        })
    );

    @Effect()
    LoadFoyer$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.LOAD_FOYER),
        switchMap(() => {
            return this.requestService._post('produits', {Type: '2'}).pipe(
                map(res => {
                    if (res) {
                        return ProjectActions.loadFoyerSuccess({data: res});
                    }
                })
            );
        })
    );

    @Effect()
    LoadPoeles$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.LOAD_POELES),
        switchMap(() => {
            return this.requestService._post('produits', {Type: '3'}).pipe(
                map(res => {
                    if (res) {
                        return ProjectActions.loadPoelesSuccess({data: res});
                    }
                })
            );
        })
    );

    @Effect()
    LoadJcbordelet$ = this.action$.pipe(
        ofType(ProjectActions.ProjectActionsTypes.LOAD_JCBORDELET),
        switchMap(() => {
            return this.requestService._post('produits', {Type: '4'}).pipe(
                map(res => {
                    if (res) {
                        return ProjectActions.loadJcbordeletSuccess({data: res});
                    }
                })
            );
        })
    );
    constructor(
        private action$: Actions,
        private requestService: RequestService,
        private router: Router,
        private store: Store<{project: ProjectState}>
    ) {}
}
