<div class="w-card">
    <div class="w-card-header text-center back-red-title" matDialogTitle>
        <mat-icon>eye</mat-icon> Demande de correction
    </div>
    <div class="w-card-body">

        <div class="row">
            <form [formGroup]="retourForm" (ngSubmit)="onSubmit()">
                <div>
                    <p *ngIf="data?.edit">{{ "SHARED_COMPONENT.RETOUR_MODAL.INFO" | translate}}</p>
                    <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ "USER.INTERFACE.CLIENT_NAME"| translate }}</mat-label>
                    <input type="text" [readonly]="true" matInput [value]="data?.project?.clientName">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label *ngIf="data?.edit">{{ "SHARED_COMPONENT.RETOUR_MODAL.LABEL_1" | translate}}</mat-label>
                        <mat-label *ngIf="data && !data.edit">{{ "SHARED_COMPONENT.RETOUR_MODAL.LABEL_2" | translate}}</mat-label>
                        <textarea [readonly]="!data ||  !data?.edit" matInput formControlName="remarque"></textarea>
                    </mat-form-field>
                    <div *ngIf="data?.edit">
                        <mat-label>{{ "SHARED_COMPONENT.RETOUR_MODAL.LABEL_3" | translate}}</mat-label> <br>
                        <button type="button" mat-raised-button (click)="retourFileEl0.click()">
                            <span>{{retourFileEl0?.files[0]?.name}}</span>
                            <span *ngIf="!retourFileEl0 || !retourFileEl0.files[0]">{{"COMMON.BROWSE" | translate}}...</span> 
                        </button>
                        <input hidden (change)="onFileSelected(retourFileEl0, 0)" #retourFileEl0 type="file">
                        <button type="button" mat-raised-button (click)="retourFileEl1.click()">
                            <span>{{retourFileEl1?.files[0]?.name}}</span>
                            <span *ngIf="!retourFileEl1 || !retourFileEl1.files[0]">{{"COMMON.BROWSE" | translate}}...</span> </button>
                        <input hidden (change)="onFileSelected(retourFileEl1, 1)" #retourFileEl1 type="file">
                        <button type="button" mat-raised-button (click)="retourFileEl2.click()">
                            <span>{{retourFileEl2?.files[0]?.name}}</span>
                            <span *ngIf="!retourFileEl2 || !retourFileEl2.files[0]">{{"COMMON.BROWSE" | translate}}...</span> 
                        </button>
                        <input hidden (change)="onFileSelected(retourFileEl2, 2)" #retourFileEl2 type="file">
                    </div>
                    <div *ngIf="data && !data.edit && data.project.remarqueFile">
                        <mat-label>{{"COMMON.ATTACHED_DOCUMENTS" | translate}}</mat-label> <br>
                        <a target="_blank" [href]="getPublic(data.project.remarqueFile)"  *ngIf="data.project.remarqueFile">{{"COMMON.FILE" | translate}} 1</a> <br>
                        <a target="_blank" [href]="getPublic(data.project.remarqueFile1)" *ngIf="data.project.remarqueFile1">{{"COMMON.FILE" | translate}} 2</a> <br>
                        <a target="_blank" [href]="getPublic(data.project.remarqueFile2)" *ngIf="data.project.remarqueFile2">{{"COMMON.FILE" | translate}} 3</a>
                    </div>
                </div>
            </form>
        </div>

        <div class="row mt-4" *ngIf="!loading">
            <div class="col-6 text-center m-auto">
                <button mat-raised-button color="warn" (click)="closeDialog()">{{"COMMON.CLOSE" | translate}}</button>
            </div>
            <div class="col-6 text-center m-auto" *ngIf="data?.edit">
                <button mat-raised-button color="primary" [disabled]="!retourForm.valid" *ngIf="!loading"
                    (click)="onSubmit()">{{"COMMON.DONE" | translate}}</button>
            </div>
        </div>
        <div class="row mt-4 text-center" *ngIf="loading">
            <mat-spinner diameter="35" *ngIf="loading"></mat-spinner>
        </div>
    </div>
</div>